<template>
  <div id="service" class="content">
    <div class="search-service">
      <div class="container service-of">
        <span class="befor-title">ทรัพย์สินทางปัญญาของ</span>
        <span class="title-inside">คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่</span>
      </div>
    </div>
    <div class="inside-page">
      <div class="container">
        <p class="count-search">
          ทรัพย์สินทางปัญญาทั้งหมด
          <span class="active">{{ total }}</span> รายการ
        </p>
        <div v-loading="loading" >
          <vue-flex-waterfall
            v-if="!loading && widthMax < 991.98"
            class="card-ip"
            :col="2"
            :col-spacing="15"
            :break-at="{ 991.98: 1 }"
            :break-by-container="false"
          >
            <cardIP
              :dataIP="dataIPs"
              v-for="(dataIPs, index) in intellectualProperty"
              :key="index"
              style="margin-top: 30px"
            />
          </vue-flex-waterfall>
          <vue-flex-waterfall
            v-else-if="!loading"
            class="card-ip"
            :col="2"
            :col-spacing="15"
            :break-by-container="false"
          >
            <cardIP
              :dataIP="dataIPs"
              v-for="(dataIPs, index) in intellectualProperty"
              :key="index"
              style="margin-top: 30px"
            />
          </vue-flex-waterfall>
        </div>
      </div>

      <div v-if="total > 10" class="group-pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :current-page="page"
          :page-size="10"
          @current-change="changePage"
        ></el-pagination>
      </div>
      <div class="text-right container text-seemore">
        <a
          href="https://patentsearch.ipthailand.go.th/DIP2013/simplesearch.php"
          target="_blank"
          >คลิกที่นี่เพื่อดูรายละเอียดทรัพย์สินทางปัญญา</a
        >
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
.text-seemore {
  padding-top: 50px;
  a {
    font-size: 20px;
    color: #9e76b4;
    @media (max-width: 400.98px) {
      
    font-size: 15px;
    }
  }
}
.card-ip {
  @media (max-width: 991.98px) {
    height: auto !important;
  }
  &.vue-flex-waterfall {
    @media (max-width: 991.98px) {
      display: block;
    }
  }
}
</style>
<script>
import VueFlexWaterfall from "vue-flex-waterfall";
import cardIP from "@/components/cardIP";
import { HTTP } from "@/service/axios.js";

export default {
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  components: {
    cardIP,
    VueFlexWaterfall,
  },
  mounted() {
    this.setParams();
    this.fetchIntellectualProperty();
  },
  methods: {
    setParams() {
      if (this.routeParams != null) {
        this.serviceId = this.routeParams.serviceId;
      }
    },
    fetchIntellectualProperty() {
      
      HTTP.get(
        `intellectual/property/${this.serviceId}/${
          (this.page - 1) * this.pageSize
        }/${10}`
      )
        .then((res) => {
          if (res.data.success) {
            this.intellectualProperty = res.data.obj;
            this.total = res.data.count;
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("fetchInnovation error", e);
        }).finally(()=>{
          this.loading = false
        })
    },
    changePage(page) {
      this.page = page;
      setTimeout(() => {
        this.fetchIntellectualProperty();
      }, 600);
      console.log("g");
      this.loading = true
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
  },
  data() {
    return {
      serviceId: "",
      intellectualProperty: [],
      widthMax: window.innerWidth,
      page: 1,
      total: 0,
      pageSize: 10,
      loading:false
    };
  },
};
</script>