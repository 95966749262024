import { render, staticRenderFns } from "./intellectualProperty.vue?vue&type=template&id=0d87a00b&"
import script from "./intellectualProperty.vue?vue&type=script&lang=js&"
export * from "./intellectualProperty.vue?vue&type=script&lang=js&"
import style0 from "./intellectualProperty.vue?vue&type=style&index=0&id=0d87a00b&prod&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports