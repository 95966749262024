<template>
  <el-card class="box-card" shadow="never">
    <div class="group-in-ip">
      <!-- <p>
        เลขที่คำขอ
        <span class="inside-active">{{ dataIP.requestNumber }}</span>
      </p> -->
      <p class="name-title">{{ dataIP.name }}</p>
    </div>
    <!-- <div class="group-in-ip">
      <el-row :gutter="30">
        <el-col :span="24">
          <el-row :gutter="30">
            <div class="set-flex">
              <el-col :span="12" :md="10">
                <p>วันที่ยื่นคำขอ</p>
              </el-col>
              <el-col :span="12" :md="14" class="tab-r">
                <p>{{ dataIP.requestDate | timeTh }}</p>
              </el-col>
            </div>
            <div class="set-flex">
              <el-col :span="12" :md="10">
                <p>วันที่รับคำขอ</p>
              </el-col>
              <el-col :span="12" :md="14" class="tab-r">
                <p>{{ dataIP.reciveDate | timeTh }}</p>
              </el-col>
            </div>
            <div class="set-flex">
              <el-col :span="12" :md="10">
                <p>วันที่ประกาศโฆษณา</p>
              </el-col>
              <el-col :span="12" :md="14" class="tab-r">
                <p>{{ dataIP.announcementDate | timeTh }}</p>
              </el-col>
            </div>
            <div class="set-flex">
              <el-col :span="12" :md="10">
                <p>วันที่รับจดทะเบียน</p>
              </el-col>
              <el-col :span="12" :md="14" class="tab-r">
                <p>{{ dataIP.registrationDate | timeTh }}</p>
              </el-col>
            </div>
            <div class="set-flex">
              <el-col :span="12" :md="10">
                <p>เลขที่จดทะเบียน</p>
              </el-col>
              <el-col :span="12" :md="14" class="tab-r">
                <p>
                  {{
                    dataIP.registrationNumber ? dataIP.registrationNumber : "-"
                  }}
                </p>
              </el-col>
            </div>
            <div class="set-flex">
              <el-col :span="12" :md="10">
                <p>รหัส PCT</p>
              </el-col>
              <el-col :span="12" :md="14" class="tab-r">
                <p>{{ dataIP.pctNumber ? dataIP.pctNumber : "-" }}</p>
              </el-col>
            </div>
            <div class="set-flex">
              <el-col :span="12" :md="10">
                <p>รหัสกลุ่ม IPC</p>
              </el-col>
              <el-col :span="12" :md="14" class="tab-r group-ipc">
                <p v-for="(pctGroup, index) in dataIP.cateIPC" :key="index">
                  {{ pctGroup ? pctGroup : "-" }}
                </p>
              </el-col>
            </div>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="group-in-ip">
      <el-row :gutter="30">
        <el-col :span="24">
          <el-row :gutter="30">
            <div class="set-flex">
              <el-col :span="12" :md="10">
                <p>ชื่อเจ้าของสิทธิ</p>
              </el-col>
              <el-col :span="12" :md="14">
                <p class="inside-active">{{ dataIP.cateInventor }}</p>
              </el-col>
            </div>
            <div class="set-flex name-invantor">
              <el-col :span="12" :md="10">
                <p>ชื่อผู้ประดิษฐ์</p>
              </el-col>
              <el-col :span="12" :md="14" class="tab-r">
                <p
                  v-for="(nameInventir, index) in dataIP.inventor"
                  :key="index"
                >
                  <span>{{ index + 1 + ". " + nameInventir }}</span>
                </p>
              </el-col>
            </div>
          </el-row>
        </el-col>
      </el-row>
    </div> -->
    <button @click="typeof dataIP.attach != 'undefined'?gotoExtra(dataIP.attach.file):null" class="btn btn-read-more">
      ทรัพย์สินทางปัญญา
    </button>
  </el-card>
</template>
<script>
export default {
  props: ["dataIP"],
  data() {
    return {
      callImage: process.env.VUE_APP_BASE_URL_GET,
    };
  },
  methods: {
    gotoExtra(val) {
      let routeData = this.callImage +'service/'+ this.dataIP.serviceId + "/" + val;
      window.open(routeData, "_blank");
    },
  },
};
</script>
<style scoped>
.box-card {
  max-width: 50%;
}
@media (max-width: 991.98px) {
  .box-card {
    max-width: 100%;
  }
}
</style>